import {
  legacy_createStore as createStore,
  combineReducers,
  applyMiddleware,
} from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { getReportReducer, submitIssueReducer } from './reducers/issueReducer'
import { getAirlineReducer } from './reducers/airlineReducer'
import { alertReducer } from './reducers/alertReducer'
import {
  submitFeedbackReducer,
  submitSilentBiddingReducer,
} from './reducers/formReducer'

const reducer = combineReducers({
  report: getReportReducer,
  submitIssue: submitIssueReducer,
  airline: getAirlineReducer,
  alert: alertReducer,
  feedback: submitFeedbackReducer,
  silentBidding: submitSilentBiddingReducer,
})
const initialState = {}
const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
