import React from 'react'
import footerImg from '../images/produly-powered-by.png'

const Footer = () => {
  return (
    <div className="footer">
      <img src={footerImg} alt="" />
    </div>
  )
}

export default Footer
