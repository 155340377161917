// Get report
export const GET_REPORT_REQUEST = 'GET_REPORT_REQUEST'
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS'
export const GET_REPORT_FAIL = 'GET_REPORT_REQUEST'

// Submit Issue
export const SUBMIT_ISSUE_REQUEST = 'SUBMIT_ISSUE_REQUEST'
export const SUBMIT_ISSUE_SUCCESS = 'SUBMIT_ISSUE_SUCCESS'
export const SUBMIT_ISSUE_FAIL = 'SUBMIT_ISSUE_FAIL'
export const SUBMIT_ISSUE_RESET = 'SUBMIT_ISSUE_RESET'
