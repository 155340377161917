import React from 'react';
import img1 from '../../src/images/unsubdelect.jpeg'
import img2 from '../../src/images/user_profile_image.jpeg'
const containerStyle = {
  padding: '40px',
  border: '1px solid #ccc',
  borderRadius: '5px',
  maxWidth: '1200px',
  margin: '0 auto',
  boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
};

const headerStyle = {
  fontSize: '34px',
  fontWeight: 'bold',
  marginBottom: '20px',
  textAlign: 'center',
  color: '#333',
};
const headerStyle1 = {
  fontSize: '26px',
  fontWeight: 'bold',
  marginBottom: '18px',
  textAlign: 'center',
  color: '#333',
};

const listItemStyle = {
  fontSize: '18px',
  marginBottom: '15px',
};

const responsiveContainerStyle = {
  width: '90%', // Adjust width for smaller screens
  margin: '0 auto',
};
const text ={
  fontSize :'20px',
  textIndent: '2em'
}
const text1 ={
  fontSize :'20px'
}
export const DataSafety = () => {
  return (
    <div style={{ ...containerStyle, ...responsiveContainerStyle }}>
      <p style={headerStyle}>Data collection and security</p>
      <p style={text}>
        &nbsp; FlyBuy takes data collection and security very seriously, ensuring that your personally identifiable information (PII) is safeguarded. We implement a comprehensive set of precautions and robust measures to protect your PII data, providing you with peace of mind and a secure experience while using our services.
      </p>
      <br></br>
      <p style={text1}>
        The personally identifiable information (PII) data collected for flight bookings includes your First Name, Last Name, Date of Birth, Email, Phone Number, Gender, Zip Code, and Address. You have the flexibility to delete your registration data from our system at any time. Taking control of your data is easy, and we've outlined the steps below to guide you through the process. Your privacy and data management are important to us, and we are committed to providing a seamless and user-friendly experience for maintaining your personal information within our platform.
      </p>
      
      <div style={{ marginTop: '20px' }}>
      <p style={headerStyle1}>Steps to delete account information in FlyBuy application</p>
      <br></br>
        <p style={listItemStyle}>1. Go to Profile and click on “Unsubscribe Account”</p>
        <br></br>
        <img src={img2} alt="Logo1" style={{ width: '100%', maxWidth: '300px', margin: '0 auto', display: 'block' }} />
        <br></br>
        <p style={listItemStyle}>2. Click on “Erase My Information” and confirm the deletion.</p>
        <br></br>
        <img src={img1} alt="Logo2" style={{ width: '100%', maxWidth: '300px', margin: '0 auto', display: 'block' }} />
      </div>
    </div>
  );
};
