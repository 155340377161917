import {
  GET_REPORT_REQUEST,
  GET_REPORT_SUCCESS,
  GET_REPORT_FAIL,
  SUBMIT_ISSUE_REQUEST,
  SUBMIT_ISSUE_SUCCESS,
  SUBMIT_ISSUE_FAIL,
  SUBMIT_ISSUE_RESET,
} from '../constants/issueConstants'

// 1. Fetch Report
export const getReportReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_REPORT_REQUEST:
      return {
        loading: true,
      }
    case GET_REPORT_SUCCESS:
      return {
        loading: false,
        data: payload,
      }
    case GET_REPORT_FAIL:
      return {
        loading: false,
        error: payload,
      }
    default:
      return state
  }
}

// 2. Submit Issue
export const submitIssueReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case SUBMIT_ISSUE_REQUEST:
      return {
        loading: true,
      }
    case SUBMIT_ISSUE_SUCCESS:
      return {
        loading: false,
        success: true,
        data: payload,
      }
    case SUBMIT_ISSUE_FAIL:
      return {
        loading: false,
        error: payload,
      }
    case SUBMIT_ISSUE_RESET:
      return {}
    default:
      return state
  }
}
