import React,{useEffect} from 'react'
import RPAInfo from '../components/RPAinfo'
import '../css/styles.css'
import '../css/general.css'
import '../css/queries.css'
import ReactGA from "react-ga4"

const DataInquiryScreen = () => {
  useEffect(()=>{
    ReactGA.send(window.location.pathname)
  },[])

  return (
    <div className="section-report-incident">
      <div className="container incident-flex">
        {/* RPA info */}
        <RPAInfo />
        <div className="form-area">
          <h2 className="heading-secondary">Data Inquiry</h2>
          <h3 className="inquiry-heading mt-2">Cancellation</h3>
          <p className="challenge-pragraph mb-2 mt-1">
            Cancellation Data currently exists.  It is dated, however. June is
            the most recent data as of September 15.{' '}
            <a href="https://www.transportation.gov/sites/dot.gov/files/2022-08/August%202022%20ATCR.pdf">
              Data
            </a>{' '}
            has been taken from the following data provided by the airlines to
            the federal government. This data will be be eliminated from
            representation on November 1.
            <br />
            <br />
            New consumer data will add to the initial data. Eventually, new data
            will replace the old data, as the initial data will expire after 60
            days
            <br />
            <br />
            All data will be scrubbed via an online engine that is created by
            RPA Consultants, LLP. The RPA engine will also remove duplicate
            data. Duplicate data will be defined as cancellations that have the
            same airline, flight number, and date. As mentioned, the RPA engine
            will remove data older than 60 days from representation on the
            DataScalp.com homepage.
            <br />
            <br />
            The rationale for removing duplicate data is because theoretically,
            there can be only one cancellation service defect per flight.
            Certainly, cancellations effect everyone. However, counting a
            cancellation for ever person effected would be unfair to the
            airlines, given that we are trying to understand and count the
            frequency of cancellations (as a sample) as it relates to all
            flights (the population). Therefore, counting each cancellation
            would frustrate the intent of what we are trying to measure and
            communicate.
            <br />
            <br />
            The DataScalp Data cognitive analytics engine will utilize consumer
            information and employ inferential statistics to develop conclusions
            about the population of airline cancellations.
          </p>
          <h3 className="inquiry-heading mt-2">Baggage</h3>
          <p className="challenge-pragraph mb-2 mt-1">
            “Baggage” data currently exists.  As of September 15, the most
            recent{' '}
            <a href="https://www.transportation.gov/sites/dot.gov/files/2022-08/August%202022%20ATCR.pdf">
              airline industry baggage data
            </a>{' '}
            is for June. DataScalp is not including baggage issues resulting
            from branded codeshare partners.
            <br />
            <br />
            New consumer input data will add to the initial data. Eventually,
            new data only will replace the initial data, as the initial data
            will expire after 60 days, and no longer be reflected on
            DataScalp.com.
            <br />
            <br />
            Like cancellations, new data will be scrubbed via an online RPA
            engine that is created by RPA Consultants, LLP. The RPA engine will
            not remove duplicate baggage data because there can be multiple
            baggage defects per flight. And, we are trying to understand the
            degree of baggage issues per flight.
            <br />
            <br />
            Like cancellations, the RPA engine will remove data older than 60
            days from representation on the DataScalp.com homepage.
            <br />
            <br />
            The DataScalp Data cognitive analytics engine will utilize consumer
            information and employ inferential statistics to develop conclusions
            about the population of airline baggage performance.
          </p>
          <h3 className="inquiry-heading mt-3">On Time</h3>
          <p className="challenge-pragraph mb-2 mt-1">
            Initially, DataScalp “On Time” data will reflect the number of
            flight delays, as reported by the airlines in federal{' '}
            <a href="https://www.transportation.gov/sites/dot.gov/files/2022-08/August%202022%20ATCR.pdf">
              industry airline data.
            </a>{' '}
            As of September 15, only June data is reported.
            <br />
            <br />
            DataScalp will rely upon this data until we have 250 independent
            consumer reports. The independent consumer reports will then be
            scrubbed for duplicates. Duplicates will be defined as identical
            airline and flight number.
            <br />
            <br />
            Once DataScalp has 250 reports, the average duration of flight delay
            will be revealed, and this will replace the number of flight delay
            content. The average flight delay will be a function of the number
            of delays and the duration of the delay. For consumers, the
            instructions will be for consumers to report the plane departure
            time. The difference between the actual and planned destination
            plane departure will be the time recorded as delay. The aggregate
            delay time will be divided by the number of delays to produce an
            average consumer delay time.
            <br />
            <br />
            The DataScalp Data cognitive analytics engine will utilize consumer
            information and employ inferential statistics to develop conclusions
            about the population of airline on-time performance.
          </p>
          <h3 className="inquiry-heading mt-3">CANCELLATION TIME TO REFUND</h3>
          <p className="challenge-pragraph mb-2 mt-1">
            Cancellation Time to Refund data for the airline industry is
            currently not public.  DataScalp will report a visual and
            quantitative representation of the Cancellation Time to Refund after
            consumers have reported at least 250 incidents.
            <br />
            <br />
            DataScalp will record the number of days that elapse from when a
            consumer initiates a cash refund request and the time a consumer
            reports receiving a cash refund. Refunds that replace one flight
            with another flight will not be considered.
            <br />
            <br />
            Like baggage, the RPA engine will not eliminate duplicate
            Cancellation Time to Refunds. Like baggage, each person could have a
            different experience. Therefore, there can be multiple experiences
            per traveler.
            <br />
            <br />
            Cancellation Time to Refund will reflect a 60-day history on
            DataScalp.com.
            <br />
            <br />
            The DataScalp Data cognitive analytics engine will utilize consumer
            information and employ inferential statistics to develop conclusions
            about the population of airline refund performance.
          </p>
        </div>
      </div>
    </div>
  )
}

export default DataInquiryScreen
