import {
  GET_AIRLINE_REQUEST,
  GET_AIRLINE_SUCCESS,
  GET_AIRLINE_FAIL,
} from '../constants/airlineConstants'

// Fetch airline data
export const getAirlineReducer = (state = { airlines: [] }, action) => {
  const { type, payload } = action
  switch (type) {
    case GET_AIRLINE_REQUEST:
      return {
        loading: true,
      }
    case GET_AIRLINE_SUCCESS:
      return {
        loading: false,
        airlines: payload,
      }
    case GET_AIRLINE_FAIL:
      return {
        loading: false,
        error: payload,
      }
    default:
      return state
  }
}
