import React from 'react'
// import './App.css'
import './css/general.css'
import './css/queries.css'
import './css/styles.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Navbar from './components/Navbar'
import HomeScreen from './screens/HomeScreen'
// import ReportIncidentScreen from './screens/ReportIncidentScreen'
import ContestScreen from './screens/ContestScreen'
import GoFundMeScreen from './screens/GoFundMeScreen'
import Footer from './components/Footer'
import MediaKitScreen from './screens/MediaKitScreen'
import SiteAdvertisingScreen from './screens/SiteAdvertisingScreen'
import SilentBiddingFormScreen from './screens/SilentBiddingFormScreen'
import DataInquiryScreen from './screens/DataInquiryScreen'
import FeedbackFormScreen from './screens/FeedbackFormScreen'
import IncidentSelector from './screens/Incident Selector'
import ReportIncidentForm from './screens/Report Incident'
import { Toaster } from 'react-hot-toast'
import ReactGA from "react-ga4"
import { PrivacyPolicy } from './screens/privacyPolicy'
import {DataSafety} from './screens/datasafety'

const TRACKING_ID = "G-BV59JKWND1"
ReactGA.initialize(TRACKING_ID)

function App() {

  return (
    <Router>
      <Navbar />
      <Toaster
        toastOptions={{
          className: '',
          style: {
            border: '1px solid #713200',
            padding: '16px',
            color: '#000',
            fontSize: '16px',
          },
        }}
      />
      {/*  */}
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        {/* <Route path="/report" element={<ReportIncidentScreen />} /> */}
        <Route path="/report" element={<IncidentSelector />} />
        <Route path="/report/:id" element={<ReportIncidentForm />} />
        <Route path="/challenge" element={<ContestScreen />} />
        <Route path="/go-fund-me" element={<GoFundMeScreen />} />
        <Route path="/media-kit" element={<MediaKitScreen />} />
        <Route path="/site-advertising" element={<SiteAdvertisingScreen />} />
        <Route path="/data-inquiry" element={<DataInquiryScreen />} />
        <Route path="/feedback" element={<FeedbackFormScreen />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/datasafety" element={<DataSafety />} />
        <Route
          path="/silent-bidding-form"
          element={<SilentBiddingFormScreen />}
        />

        <Route path="/uk" element={<HomeScreen />} />
        <Route path="/uk/report" element={<IncidentSelector />} />
        <Route path="/uk/report/:id" element={<ReportIncidentForm />} />
        <Route path="/uk/challenge" element={<ContestScreen />} />
        <Route path="/uk/go-fund-me" element={<GoFundMeScreen />} />
        <Route path="/uk/media-kit" element={<MediaKitScreen />} />
        <Route path="/uk/site-advertising" element={<SiteAdvertisingScreen />} />
        <Route path="/uk/data-inquiry" element={<DataInquiryScreen />} />
        <Route path="/uk/feedback" element={<FeedbackFormScreen />} />
        <Route path="/uk/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/uk/datasafety" element={<DataSafety />} />
        <Route path="/uk/silent-bidding-form" element={<SilentBiddingFormScreen />} />
      </Routes>
      <Footer />
    </Router>
  )
}

export default App
