import React, { useRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import '../css/navbar.css'
import '../css/general.css'
import '../css/queries.css'
import logo from '../images/DataScalp-elevate-your-vocie.PNG'

const Navbar = () => {
  const header = useRef(null)
  const btnMobileNav = useRef(null)
  const location = useLocation()

  // Determine if the path is UK-based
  const basePath = location.pathname.startsWith('/uk') ? '/uk' : '';

  const clickHandler = () => {
    header.current.classList.toggle('nav-open')
  }

  return (
    <header ref={header} className="header">
      <div className="temp-logo">
        <Link to={`${basePath}/`}>
          <img className="logo-img" src={logo} alt="DataScalp Logo" />
        </Link>
      </div>
      <nav className="main-nav">
        <ul className="main-nav-list">
          <li>
            <div className="dropdown">
              <span className="dropbtn ">
                <Link onClick={clickHandler} to={`${basePath}/feedback`}>
                  Feedback
                </Link>
              </span>
            </div>
          </li>

          <li>
            <div className="dropdown">
              <span className="dropbtn">Competition</span>
              <div className="dropdown-content">
                <Link onClick={clickHandler} to={`${basePath}/challenge`}>
                  DataScalp Challenge
                </Link>
              </div>
            </div>
          </li>

          <li>
            <div className="dropdown">
              <span className="dropbtn">Contact</span>
              <div className="dropdown-content">
                <Link onClick={clickHandler} to={`${basePath}/media-kit`}>
                  Media
                </Link>
                <Link onClick={clickHandler} to={`${basePath}/site-advertising`}>
                  Site Advertising
                </Link>
                <Link onClick={clickHandler} to={`${basePath}/data-inquiry`}>
                  Data Inquiry
                </Link>
                <Link onClick={clickHandler} to={`${basePath}/go-fund-me`}>
                  Support DataScalp
                </Link>
              </div>
            </div>
          </li>

          <li>
            <div className="dropdown">
              <nobr className="dropbtn ">
                <Link
                  className="signIn-Button"
                  onClick={clickHandler}
                  to={`${basePath}/report`}
                >
                  Report Incident
                </Link>
              </nobr>
            </div>
          </li>
        </ul>
      </nav>
      <button ref={btnMobileNav} onClick={clickHandler} className="btn-mobile-nav">
        <ion-icon className="icon-mobile-nav" name="menu-outline"></ion-icon>
        <ion-icon className="icon-mobile-nav" name="close-outline"></ion-icon>
      </button>
    </header>
  )
}

export default Navbar
