import React from 'react'
import '../css/general.css'
import '../css/styles.css'
// import rpa from '../images/rpa.PNG'
// import ad from '../images/adv1.PNG'
// import ad from '../images/adv-1.png'
// import ad from '../images/adv4.png'
import img1 from '../images/img1.png'
import img2 from '../images/img2.png'
import img3 from '../images/img3.png'
import logo from '../images/DataScalp-elevate-your-vocie.PNG'
import twitter from '../images/svg/twitter.svg'
import linkedin from '../images/svg/linkedin.svg'

const RPAInfo = () => {
  return (
    <div className="ad">
      <div className="rpa-info">
        {/* <a href="https://www.rpaconsultants.com/">
          <img src={ad} alt="" />
        </a> */}
        <img src={img1} alt=''/>
        <img src={img2} alt=''/>
        <img src={img3} alt=''/>
        <div className="social-container">
          <img className="social-logo" src={logo} alt="" />
          <span className="social-text">Connect On Social</span>
          <a
            href="https://twitter.com/Data_Scalp"
            target="_blank"
            rel="noopener noreferrer"
            className="social-button-container"
          >
            <nobr className="social-button-text">Follow us on Twitter</nobr>
            <img src={twitter} alt="" className="social-icons" />
          </a>
          <a
            href="https://www.linkedin.com/company/datascalp"
            className="social-button-container"
            target="_blank"
            rel="noopener noreferrer"
          >
            <nobr className="social-button-text">Follow us on LinkedIn</nobr>
            <img
              style={{
                height: '40px',
                width: '40px',
              }}
              className="social-icons"
              src={linkedin}
              alt=""
            />
          </a>
        </div>
      </div>
    </div>
  )
}

export default RPAInfo
