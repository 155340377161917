import React,{useEffect} from 'react'
import RPAInfo from '../components/RPAinfo'
import '../css/styles.css'
import '../css/general.css'
import '../css/queries.css'
import ReactGA from "react-ga4"

const GoFundMeScreen = () => {
  useEffect(()=>{
    // ReactGA.pageview(window.location.pathname)
    ReactGA.send(window.location.pathname)
  },[])

  return (
    <div className="section-report-incident">
      <div className="container incident-flex">
        {/* RPA info */}
        <RPAInfo />
        <div className="form-area">
          <h2 className="heading-secondary">Go Fund Me</h2>
          <ul className="challenge-list">
            <li>The consumer platform</li>
            <li>Intended to help people</li>
            <li>Designed for the people</li>
            <li>By the people</li>
          </ul>
          <p className="challenge-pragraph">
            DataScalp funded by the people just like you.
          </p>
          <a
            className="button-primary"
            href="https://www.gofundme.com/f/hold-firms-accountable-demand-better?utm_source=customer&utm_medium=copy_link&utm_campaign=p_cf+share-flow-1"
          >
            Support DataScalp
          </a>
        </div>
      </div>
    </div>
  )
}

export default GoFundMeScreen
