import React, { useState, useEffect } from 'react'
import RPAInfo from '../components/RPAinfo'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import '../css/styles.css'
import '../css/general.css'
import '../css/queries.css'
import { submitSilentBid } from '../actions/formAction'
import { SUBMIT_SILENT_BID_RESET } from '../constants/formConstants'
import ReactGA from "react-ga4"


const SilentBiddingFormScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // useSelector
  const silentBidding = useSelector((state) => state.silentBidding)
  const { success } = silentBidding

  // useState
  const [orgName, setOrgName] = useState('')
  const [orgType, setOrgType] = useState('')
  const [orgRep, setOrgRep] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [bid, setBid] = useState('')
  const [months, setMonths] = useState('')

  // useEffect
  useEffect(() => {
    ReactGA.send(window.location.pathname)
    if (success) {
      navigate('/')
      dispatch({ type: SUBMIT_SILENT_BID_RESET })
    }
  }, [success, navigate, dispatch])

  // Handlers
  // 1. Submit handler
  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      submitSilentBid({
        orgName,
        orgType,
        orgRep,
        phone,
        email,
        bid,
        months,
      })
    )
  }

  return (
    <div className="section-report-incident">
      <div className="container incident-flex">
        {/* RPA info */}
        <RPAInfo />
        <div className="sbf-area">
          <h2 className="heading-secondary mb-2">Silent Bidding Form</h2>
          <form onSubmit={submitHandler} className="bidding-area">
            {/* Organization Name */}
            <label>Organization Name</label>
            <input
              value={orgName}
              onChange={(e) => setOrgName(e.target.value)}
              type="text"
              required
            ></input>

            {/* Organization Type */}
            <label>Organization Type</label>
            <input
              value={orgType}
              onChange={(e) => setOrgType(e.target.value)}
              type="text"
              required
            ></input>

            {/* Org Reprensentative */}
            <label>Organization Repesentative</label>
            <input
              value={orgRep}
              onChange={(e) => setOrgRep(e.target.value)}
              type="text"
              required
            ></input>

            {/* Phone */}
            <label>Contact Phone</label>
            <input
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              type="number"
              required
            ></input>

            {/* Email */}
            <label>Contact Email</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              required
            ></input>

            {/* Monthly Bid */}
            <label>Monthly Bid Amount</label>
            <input
              value={bid}
              onChange={(e) => setBid(e.target.value)}
              type="number"
              required
            ></input>

            {/* No of Months */}
            <label>Number of Months</label>
            <input
              value={months}
              onChange={(e) => setMonths(e.target.value)}
              type="number"
              required
            ></input>

            {/* Submit Button */}
            <button type="submit" className="feedback-submit-button">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default SilentBiddingFormScreen
