import React,{useEffect} from 'react'
import RPAInfo from '../components/RPAinfo'
import '../css/styles.css'
import '../css/general.css'
import '../css/queries.css'
import { Link } from 'react-router-dom'
import ReactGA from "react-ga4"


const SiteAdvertisingScreen = () => {
  useEffect(()=>{
    ReactGA.send(window.location.pathname)
  },[])

  return (
    <div className="section-report-incident">
      <div className="container incident-flex">
        {/* RPA info */}
        <RPAInfo />
        <div className="form-area">
          <h2 className="heading-secondary">Site Advertising</h2>
          <p className="challenge-pragraph mb-2 mt-1">
            Site Advertising generally occurs on a monthly basis. Site
            Advertising occurs on a silent bidder basis. Bidder forms may be
            submitted each month, until 12:00 am EST on the 27th of each month.
            The bidder with the highest monthly bid will be contacted on the
            27th of each month by 10 am EST and will have until the 12:00 pm EST
            on the 28th of the same month to satisfy their bid in full, or the
            bid will be awarded to the next highest “viable” bidder.
          </p>
          <div className="flex flex-center">
            <Link className="button-primary" to="/silent-bidding-form">
              Silent Bidding Form
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SiteAdvertisingScreen
