import {
  SUBMIT_FEEDBACK_REQUEST,
  SUBMIT_FEEDBACK_SUCCESS,
  SUBMIT_FEEDBACK_FAIL,
  SUBMIT_SILENT_BID_REQUEST,
  SUBMIT_SILENT_BID_SUCCESS,
  SUBMIT_SILENT_BID_FAIL,
} from '../constants/formConstants'
import axios from 'axios'
//import env from "react-dotenv"

// 1. Submit Feedback
export const submitFeedback = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SUBMIT_FEEDBACK_REQUEST,
    })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const res = await axios.post(
      `${process.env.REACT_APP_ADDRESS}/api/forms/feedback`,
      data,
      config
    )

    dispatch({
      type: SUBMIT_FEEDBACK_SUCCESS,
      payload: res.data,
    })
  } catch (error) {
    dispatch({
      type: SUBMIT_FEEDBACK_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// 2. Submit Silent Bid
export const submitSilentBid = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SUBMIT_SILENT_BID_REQUEST,
    })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const res = await axios.post(
      `${process.env.REACT_APP_ADDRESS}/api/forms/silent-bidding`,
      data,
      config
    )

    dispatch({
      type: SUBMIT_SILENT_BID_SUCCESS,
      payload: res.data,
    })
  } catch (error) {
    dispatch({
      type: SUBMIT_SILENT_BID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
