import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import RPAInfo from '../components/RPAinfo'
import '../css/styles.css'
import '../css/general.css'
import '../css/queries.css'
import { submitFeedback } from '../actions/formAction'
import { SUBMIT_FEEDBACK_RESET } from '../constants/formConstants'
import ReactGA from "react-ga4"


const FeedbackFormScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // useSelector
  const stateFeedback = useSelector((state) => state.feedback)
  const { success } = stateFeedback

  // useState
  const [likes, setLikes] = useState('')
  const [dislikes, setDislikes] = useState('')
  const [feedback, setFeedback] = useState('')

  // useEffect
  useEffect(() => {
    ReactGA.send(window.location.pathname)
    if (success) {
      navigate('/')
      dispatch({ type: SUBMIT_FEEDBACK_RESET })
    }
  }, [success, navigate, dispatch])

  // Handlers
  // 1. Submit handler
  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      submitFeedback({
        likes,
        dislikes,
        feedback,
      })
    )
  }

  return (
    <div className="section-report-incident">
      <div className="container incident-flex">
        {/* RPA info */}
        <RPAInfo />
        <div className="sbf-area">
          <h2 className="heading-secondary mb-2">Feedback Form</h2>
          <form onSubmit={submitHandler} className="feedback-form-area">
            {/* Likes */}
            <label>
              Please List / Rank from top to bottom what you like about
              DataScalp (ex. GUI Interface, Dashboard,Reporting)
            </label>
            <textarea
              value={likes}
              onChange={(e) => setLikes(e.target.value)}
              rows="5"
              required
            ></textarea>

            {/* Dislikes */}
            <label>
              List / Rank from top to bottom what you don’t like about DataScalp
              (ex. GUI Interface, Dashboard, Reporting)
            </label>
            <textarea
              value={dislikes}
              onChange={(e) => setDislikes(e.target.value)}
              rows="5"
              required
            ></textarea>

            {/* Feedback */}
            <label>Please Share Your Feedback in 200 words or less</label>
            <textarea
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              rows="5"
              required
            ></textarea>

            {/* Submit Button */}
            <button type="submit" className="feedback-submit-button">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default FeedbackFormScreen
