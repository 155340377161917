import {
  SUBMIT_FEEDBACK_REQUEST,
  SUBMIT_FEEDBACK_SUCCESS,
  SUBMIT_FEEDBACK_FAIL,
  SUBMIT_SILENT_BID_REQUEST,
  SUBMIT_SILENT_BID_SUCCESS,
  SUBMIT_SILENT_BID_FAIL,
  SUBMIT_FEEDBACK_RESET,
  SUBMIT_SILENT_BID_RESET,
} from '../constants/formConstants'

// 1. Feedback reducer
export const submitFeedbackReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case SUBMIT_FEEDBACK_REQUEST:
      return {
        loading: true,
      }
    case SUBMIT_FEEDBACK_SUCCESS:
      return {
        loading: false,
        success: true,
        data: payload,
      }
    case SUBMIT_FEEDBACK_FAIL:
      return {
        loading: false,
        error: payload,
      }
    case SUBMIT_FEEDBACK_RESET:
      return {}
    default:
      return state
  }
}

// 1. Feedback reducer
export const submitSilentBiddingReducer = (state = {}, action) => {
  const { type, payload } = action
  switch (type) {
    case SUBMIT_SILENT_BID_REQUEST:
      return {
        loading: true,
      }
    case SUBMIT_SILENT_BID_SUCCESS:
      return {
        loading: false,
        success: true,
        data: payload,
      }
    case SUBMIT_SILENT_BID_FAIL:
      return {
        loading: false,
        error: payload,
      }
    case SUBMIT_SILENT_BID_RESET:
      return {}
    default:
      return state
  }
}
