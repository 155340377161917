import React,{useEffect} from 'react'
import RPAInfo from '../components/RPAinfo'
import '../css/styles.css'
import '../css/general.css'
import '../css/queries.css'
import ReactGA from "react-ga4"

const MediaKitScreen = () => {
  useEffect(()=>{
    ReactGA.send(window.location.pathname)
  },[])

  return (
    <div className="section-report-incident">
      <div className="container incident-flex">
        {/* RPA info */}
        <RPAInfo />
        <div className="form-area">
          <h2 className="heading-secondary">Media Kit</h2>
          <p className="challenge-pragraph mb-2 mt-1">
            DataScalp Media kits are available by clicking the Media Kit button
            above. The DataScalp media kit includes quotes, DataScalp rational,
            information on the founder, and the pitch deck. For an immediate
            response from our media team, please email to Lisa at
            Lisa@bospar.com or Kourtney at Kourtney@Bospar.com for a reply
            within 20 minutes.
          </p>
          <div className="flex flex-center">
            {/* <Link className="button-primary" to="">
              Media Kit
            </Link> */}
            <a
              className="button-primary"
              href="mailto:lisa@bospar.com;Kourtney@bospar.com?subject=DataScalp&body=Hi. I would like to discuss DataScalp with you at your earliest convenience. Please contact me at the following number."
            >
              Media Kit
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MediaKitScreen
