import React,{useEffect} from 'react'
import RPAInfo from '../components/RPAinfo'
import '../css/styles.css'
import '../css/general.css'
import '../css/queries.css'
import ReactGA from "react-ga4"


const ContestScreen = () => {
  useEffect(()=>{
    ReactGA.send(window.location.pathname)
  },[])
  
  return (
    <div className="section-report-incident">
      <div className="container incident-flex">
        {/* RPA info */}
        <RPAInfo />
        <div className="form-area">
          <h2 className="heading-secondary">The $10,000 DataScalp Challenge</h2>
          <p className="challenge-pragraph">
            DataScalp wants to use your commercial ad! We are looking for the
            best video example of someone using the DataScalp platform. Post a
            video on your favorite social media for a chance to win $10,000.
            DataScalp will use your video as their commercial ad, which could
            potentially land on TV. Conditions and Rules apply.
          </p>
          <h3 className="challenge-heading mt-3">Rules:</h3>
          <ul className="challenge-list">
            <li>
              DataScalp use includes viewing DataScalp at DataScalp. Com, or
              reporting legitimate poor airline result(s) via our reporting
              function (data must reflect details of a legitimate airline
              issue).
            </li>
            <li>
              Competitors can enter the DataScalp challenge on any day prior to
              March 16, 2023.
            </li>
            <li>Multiple entries per person are allowed.</li>
            <li>
              Acceptable social media platforms include YouTube, Facebook, Tik
              Tok, Twitter and LinkedIn.
            </li>
            <li>
              {' '}
              On March 16, 2023 at 12:00 am the DataScalp Challenge ends.
            </li>
            <li>
              DataScalp will be the sole arbiter of the DataScalp Challenge
              winner.
            </li>
            <li>There will be one DataScalp winner.</li>
            <li>
              DataScalp will determine the winner based upon multiple factors
              such as but not limited to:
            </li>
            <ul className="challenge-nested">
              <li> Ad creativity</li>
              <li>Content</li>
              <li>Credibility</li>
              <li>Popularity – using number of views as a proxy</li>
            </ul>
          </ul>
          <h3 className="challenge-heading mt-3">Conditions:</h3>
          <ul className="challenge-list">
            <li>Anyone may enter prior to May 16, 2022</li>
            <li>
              DataScalp must generate revenue that “comfortably” exceeds the
              prize obligations. “Comfortably” will be defined by DataScalp.
            </li>
            <li>
              False, misleading, and/or fabricated information reported via our
              reporting function will result in immediate disqualification from
              the DataScalp Challenge, and could potentially result in legal
              consequences.
            </li>
            <li>
              DataScalp contestants must forward contact information and respond
              within 10 days after initial preferred contact.
            </li>
            <li>
              The DataScalp winner will be expected to have demonstrated “high
              character “ within their prior online presence.
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default ContestScreen
