// Feedback Form
export const SUBMIT_FEEDBACK_REQUEST = 'SUBMIT_FEEDBACK_REQUEST'
export const SUBMIT_FEEDBACK_SUCCESS = 'SUBMIT_FEEDBACK_SUCCESS'
export const SUBMIT_FEEDBACK_FAIL = 'SUBMIT_FEEDBACK_FAIL'
export const SUBMIT_FEEDBACK_RESET = 'SUBMIT_FEEDBACK_RESET'

// Silent Bidding Form
export const SUBMIT_SILENT_BID_REQUEST = 'SUBMIT_SILENT_BID_REQUEST'
export const SUBMIT_SILENT_BID_SUCCESS = 'SUBMIT_SILENT_BID_SUCCESS'
export const SUBMIT_SILENT_BID_FAIL = 'SUBMIT_SILENT_BID_FAIL'
export const SUBMIT_SILENT_BID_RESET = 'SUBMIT_SILENT_BID_RESET'
