import React from 'react'

 

export const PrivacyPolicy = () => {

  return (

    <div className="container">

    <h2 style={{fontSize: '20px'}}><b>PRIVACY NOTICE</b></h2>
    <p style={{fontSize: '18px'}}><b>Last updated August 1, 2023</b> </p>



    <p style={{fontSize: '16px'}}>Thank you for choosing FlyGuy Airline Booking App ("App", "we", "us", or "our"). This Privacy Policy outlines how
      we collect, use, disclose, <br></br>and protect your personal information when you use our mobile application and related
      services. By using our App, you agree to the practices described in this Privacy Policy.</p>

    <h4 style={{color: "#2596be",fontSize: '18px'}}>1. Information We Collect </h4>
    <h6 style={{fontSize: '16px'}}><b>We may collect the following types of information:
      </b></h6>
      <p style={{fontSize: '16px'}}><b>Personal Information:</b> This includes your name, email address, phone number, and payment information when
        you create an account or make bookings through the App.</p>

      <p style={{fontSize: '16px'}}><b>Booking Information:</b> We collect details of your flight bookings, travel preferences, travel history, and
        other relevant travel information to provide our services effectively.</p>
      <p style={{fontSize: '16px'}}><b>Log Data:</b> Our servers automatically record certain information about how you use the App, including your
        IP address, app usage, crash reports, and browsing history.</p>

      <p style={{fontSize: '16px'}}><b>Cookies and Similar Technologies: </b> We do not use cookies and similar tracking technologies to collect
        information about your interactions with the App and to personalize your experience.
      </p>



      <h4 style={{color: " #2596be",fontSize: '18px'}}>2. How We Use Your Information</h4>
      <h6 style={{fontSize: '16px'}}><b>We use your information for various purposes, including:
        </b></h6>
      <ul style={{fontSize: '16px'}}>
        <li>Providing and improving our services. </li>
        <li> Personalizing your experience and suggesting relevant flights and travel options.</li>
        <li>Processing transactions and sending booking confirmations.</li>
        <li> Communicating with you about your bookings, promotions, and updates.</li>
        <li>Analyzing and improving the App's performance and functionality.</li>
        <li> Complying with legal obligations and protecting our rights.</li>
      </ul>


      <h4 style={{color: " #2596be",fontSize: '16px'}}>3. Information Sharing and Disclosure</h4>
      <h6 style={{fontSize: '16px'}}><b>We may share your information in the following circumstances:</b></h6>
      <p style={{fontSize: '16px'}}><b>Service Providers: </b>We may share your information with third-party service providers who assist us in
        providing our services, such as payment processors, customer support, and analytics.
      </p>
      <p style={{fontSize: '16px'}}><b>Legal Requirements: </b>We may disclose your information when required by law, such as in response to a
        subpoena or other legal process.
      </p>
      <p style={{fontSize: '16px'}}><b>Business Transfers: </b>In the event of a merger, acquisition, or sale of all or a portion of our assets,
        your information may be transferred as part of that transaction.
      </p>

      <h4 style={{color: " #2596be",fontSize: '16px'}}>4. Your Choices</h4>
      <p style={{fontSize: '16px'}}><b>Account Information: </b>You can access and update your account information through the App's settings.</p>


      <h4 style={{color: " #2596be",fontSize: '16px'}}>5. Security</h4>
      <p style={{fontSize: '16px'}}>We take reasonable measures to protect your personal information, but no method of transmission over the
        internet or electronic storage is completely secure. We cannot guarantee the absolute security of your data.
        </p>

      <h4 style={{color: " #2596be",fontSize: '16px'}}>6. Children's Privacy</h4>
      <p style={{fontSize: '16px'}}>The App is not intended for children under the age of 17. We do not knowingly collect personal information from
        children under 17. If you believe we have collected information from a child under 17, please contact us
        immediately.

      </p>

      <h4 style={{color: " #2596be",fontSize: '16px'}}>7. Changes to this Privacy Policy</h4>
      <p style={{fontSize: '16px'}}>We may update this Privacy Policy from time to time. The "Effective Date" at the top of the policy indicates
        when the latest revisions were made. We recommend reviewing this policy periodically for any changes.

      </p>

      <h4 style={{color: " #2596be",fontSize: '16px'}}>8. Contact Us</h4>
      <p style={{fontSize: '16px'}}>If you have any questions or concerns about this Privacy Policy, please contact us at <a href=" flyguy@datascalp.com. "> flyguy@datascalp.com.</a>
      </p>
       <p style={{fontSize: '16px'}}>
        By using the FlyGuy Airline Booking App, you acknowledge that you have read and understood this Privacy Policy and agree to its terms.</p>

      
  </div>

  )

}